<template>
  <main class="table-font ">
    <h1>All Wallet Transactions Logs</h1>
    <div class="flex flex-col">
      <div
        class="flex justify-between items-center m-3 flex-grow-0 flex-shrink"
      >
        <div class="">
          <div class="flex time-filter">
            <span class="mr-4">From: </span>
            <input
              type="date"
              class="inline-block border-2 px-2 rounded-sm mr-2"
              name=""
              id=""
              v-model="dateData.start_date"
            />
            <input
              type="time"
              class="inline-block border-2 px-2 rounded-sm mr-2"
              name=""
              id=""
              v-model="dateData.startTime"
            />
          </div>
          <div class="flex time-filter mt-2">
            <span class="mr-9">To: </span>
            <input
              type="date"
              class="inline-block border-2 px-2 rounded-sm mr-2"
              name=""
              id=""
              v-model="dateData.end_date"
            />
            <input
              type="time"
              class="inline-block border-2 px-2 rounded-sm mr-2"
              name=""
              id=""
              v-model="dateData.endTime"
            />
          </div>
          <div class="flex mt-3">
            <button
              @click="getTransactionLogs"
              class="block mr-2 uppercase shadow bg-blue-800 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white text-xs py-2 px-8 rounded"
            >
              Download Records
            </button>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import moment from "moment";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  name: "Loans",
  data() {
    return {
      moment,
      dateRange: [],
      isLoading: false,
      dateData: null,
    };
  },
  components: {
    DatePicker,
  },
  created() {
    const d = new Date();
    this.dateData = {
      start_date: new Date(d.setDate(d.getDate() - 1)).toJSON().split("T")[0],
      end_date: new Date().toJSON().split("T")[0],
      startTime: "00:00",
      endTime: this.moment(d).format("HH:mm"),
    };
  },

  methods: {
    async getTransactionLogs() {
      this.isLoading = true;
      try {
        let payload = { date: this.dateData, isDownload: true };

        // for (let key in query) {
        //   let value = query[key];
        //   if ((value === null || value === undefined) && key === "walletId") {
        //     value = "null";
        //   }
        //   if (value !== null && value !== undefined) {
        //     params.append(key, value);
        //   }
        // }

        let res = await this.$store.dispatch(
          "GET_ALL_WALLETS_TRANSACTIONS_LOGS",
          payload
        );
        alert(res.message);
        return res;
      } catch (error) {
        console.log(error, "error");
      } finally {
        this.isLoading = false;
      }
    },
  },
  async mounted() {
    this.dateRange = [
      moment()
        .startOf("day")
        .toDate(),
      moment().toDate(),
    ];
  },
};
</script>
